import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { fetchCollection, fetchSingleton } from '../api';
import { Loader } from '../components';
import { Page, Service } from '../types';

const Services = () => {

  const serviceResponse = useQuery("services", () => fetchCollection('get/services'));
  const services: Service[] = serviceResponse && serviceResponse.data && serviceResponse.data.entries;

  const contentResponse = useQuery("serviceContent", () => fetchSingleton('get/services'));
  const pageContent: Page = contentResponse && contentResponse.data;

  if (serviceResponse.isLoading || contentResponse.isLoading) {
    return <Loader />;
  }

  return (
    <main>
      <Helmet>
        <title>Salon 304 Services</title>
      </Helmet>
      <h1>{pageContent.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: pageContent.content }}></div>
      <div className="flex-container">
        {services && services.map((service: Service) => {
          return (
            <div className="service-container">
              <div className="card service">
                <h3>{service.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: service.content }}></div>
              </div>
            </div>
          )
        })}
      </div>
    </main>
  )
}

export default Services;