import React from 'react';

class Appointments extends React.Component {
  render() {
    return (
      <div id="appointments">
        <h2>Interested In Scheduling An Appointment?</h2>
        <p>
          Booking an appointment online is simple.<br />
          Select your date, time, stylist, and service and we’ll see you here.
        </p>
        <a href="https://www.vagaro.com/salon304" target="_blank" rel="noreferrer" className="button">
          <i className="fas fa-calendar-check"></i> Appointments / Online Store
        </a>
      </div>
    )
  }
}

export default Appointments;