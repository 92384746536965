import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { fetchSingleton } from '../api';
import { Loader } from '../components';
import { ContactInformation } from '../types';

const Contact = () => {

  const contentResponse = useQuery("contactContent", () => fetchSingleton('get/contact'));
  const contact: ContactInformation = contentResponse && contentResponse.data;

  if (contentResponse.isLoading) {
    return <Loader />;
  }

  return (
    <main>
      <Helmet>
        <title>Salon 304 Contact</title>
      </Helmet>
      <h1>Contact Us</h1>
      <div className="flex-container">
        <div className="contact-container">
          <div className="card">
            <form action="https://formspree.io/f/mgepdnwz" method="POST">
              <div className="field">
                <label> Name:</label>
                <input type="text" name="your_name" />
              </div>
              <div className="field">
                <label> Phone:</label>
                <input type="text" name="your phone" />
              </div>
              <div className="field">
                <label> Email:</label>
                <input type="text" name="_replyto" />
              </div>
              <div className="field">
                <label> Message:</label>
                <textarea name="message"></textarea>
              </div>

              <button className="button" type="submit" style={{ float: 'right' }}>Send</button>
            </form>
          </div>
        </div>
        <div className="contact-container">
          <div className="card">
            <div>{contact.address}</div>
            <div className="city-state-zip">{contact.citystatezip}</div>
            <div>{contact.phone}</div>
            <div>{contact.email}</div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Contact;