import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { fetchCollection, fetchSingleton } from '../api';
import { Loader, Slider } from '../components';
import { Page, Stylist } from '../types';

const Home = () => {

  const stylistResponse = useQuery("stylists", () => fetchCollection('get/stylists'));
  const stylists: Stylist[] = stylistResponse && stylistResponse.data && stylistResponse.data.entries;

  const contentResponse = useQuery("homeContent", () => fetchSingleton('get/homepage'));
  const pageContent: Page = contentResponse && contentResponse.data;

  if (stylistResponse.isLoading || contentResponse.isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Salon 304</title>
      </Helmet>
      <Slider />
      <main className="homepage">
        <h1>{pageContent.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: pageContent.content }}></div>
      </main>
      <div id="stylists">
        <div className="figures">
          {stylists.map((stylist) => {
            return (
              <figure key={stylist._id}>
                <img src={`https://www.salon304.net${stylist.photo.path}`} alt="" />
                <figcaption>{stylist.name}<br /><small>{stylist.info}</small></figcaption>
              </figure>
            )
          })}
        </div>
        <Link to="/stylists" className="button">Learn More About Our Stylists</Link>
      </div>
    </React.Fragment>
  )
}

export default Home;