import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { fetchCollection } from '../api';
import { Loader } from '../components';
import { Product } from '../types';

const Products = () => {

  const { isLoading, data } = useQuery("products", () => fetchCollection('get/products'));

  const products: Product[] = data && data.entries;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <main>
      <Helmet>
        <title>Salon 304 Products</title>
      </Helmet>
      <h1>Our Products</h1>
      <div className="flex-container">
        {products.map((product: Product) => {
          return (
            <div className="product-container">
              <div className="card product">
                <h3>{product.title}</h3>
                <div className="content" dangerouslySetInnerHTML={{ __html: product.content }}></div>
                <img src={`https://www.salon304.net/${product.photo.path}`} alt="" />
              </div>
            </div>
          )
        })}
      </div>
    </main>
  )
}

export default Products;