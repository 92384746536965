import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import { useQuery } from 'react-query';
import { fetchCollection } from '../api';
import { Loader } from '../components';
import { Gallery, Stylist } from '../types';

Modal.setAppElement('#root');

const Stylists = () => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [stylistIndex, setStylistIndex] = useState(0);
  const { isLoading, data } = useQuery("stylists", () => fetchCollection('get/stylists'));

  const stylists: Stylist[] = data && data.entries;

  const openModal = (index: number) => {
    setModalIsOpen(true);
    setStylistIndex(index);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <main>
        <Helmet>
          <title>Salon 304 Stylists</title>
        </Helmet>
        <h1>Our Stylists</h1>
        <div className="flex-container">
          {stylists && stylists.map((stylist: Stylist, index) => {
            return (
              <div className="card-container" key={index}>
                <div className="card stylist">
                  <div className="stylist-image">
                    <img src={`https://www.salon304.net/${stylist && stylist.photo && stylist.photo.path}`} alt="" />
                    {stylist.gallery && (
                      <button onClick={() => openModal(index)} className="button small" style={{ width: '100%' }}>
                        View Gallery
                      </button>
                    )}
                  </div>
                  <div className="stylist-info">
                    <h2>{stylist.name}</h2>
                    <h3>{stylist.info}</h3>
                    <div className="content" dangerouslySetInnerHTML={{ __html: stylist.about }}></div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </main>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      >
        <div className="modalHeader">
          <h3>
            Follow {stylists[stylistIndex].handle} to see more work
            </h3>
          <button onClick={closeModal} className="button small">x</button>
        </div>

        <div className="flex-container">
          {stylists[stylistIndex].gallery.map((image: Gallery) => {
            return (
              <div className="gallery-container" key={image.meta.asset}>
                <div className="card gallery">
                  <img src={`https://www.salon304.net/${image.path}`} alt="" />
                </div>
              </div>
            )
          })}
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Stylists;