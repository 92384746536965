import React from 'react';
import { Appointments, Footer, Header } from '../components';

const ClientLayout = (props: any) => {
  return (
    <React.Fragment>
      <Header />
      {props.children}
      <Appointments />
      <Footer />
    </React.Fragment>
  )
}

export { ClientLayout };