export async function fetchCollection(path: string) {
  const res = await fetch(`https://www.salon304.net/data/api/collections/${path}`, {
    headers: { 'Cockpit-Token': '784bf0362085722aa40fffbec291c5' }
  });
  const json = await res.json();
  return json;
}

export async function fetchSingleton(path: string) {
  const res = await fetch(`https://www.salon304.net/data/api/singletons/${path}`, {
    headers: { 'Cockpit-Token': 'b795f3c44be68e43b72119b25e7134' }
  });
  const json = await res.json();
  return json;
}
