import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { fetchCollection } from '../api';
import { Loader } from '../components';
import { Hour } from '../types';

const Directions = () => {

  const { isLoading, data } = useQuery("directions", () => fetchCollection('get/hours'));

  const hours: Hour[] = data && data.entries;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <main>
      <Helmet>
        <title>Salon 304 Directions</title>
      </Helmet>
      <h1>Hours &amp; Directions</h1>
      <div className="flex-container">
        {hours.map((hour: Hour) => {
          return (
            <div className="card-container">
              <div className="card hours">
                <span className="day">{hour.day}</span>
                <span className="hour">{hour.hours}</span>
              </div>
            </div>
          )
        })}
      </div>
      <div className="responsive-map">
        <iframe
          title="directions"
          src="https://www.google.com/maps/embed?pb=!1m17!1m8!1m3!1d1521.6443800040588!2d-80.107333!3d40.291547!3m2!1i1024!2i768!4f13.1!4m6!3e6!4m0!4m3!3m2!1d40.291546499999995!2d-80.1073333!5e0!3m2!1sen!2sus!4v1559863438163!5m2!1sen!2sus"
          width="600"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
        ></iframe>
      </div>
    </main>
  )
}

export default Directions;