import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { fetchSingleton } from '../api';
import { Loader } from '../components';
import { Page } from '../types';

const Appointments = () => {

  const contentResponse = useQuery("appointmentsContent", () => fetchSingleton('get/appointments'));
  const pageContent: Page = contentResponse && contentResponse.data;

  if (contentResponse.isLoading) {
    return <Loader />;
  }

  return (
    <main>
      <Helmet>
        <title>Salon 304 Appointments</title>
      </Helmet>
      <div className="appointments-title">
        <h1>{pageContent.title}</h1>
        <div>
          <a href="https://www.vagaro.com/salon304" target="_blank" rel="noreferrer" className="button small">
            <i className="fas fa-calendar-check"></i> Appointments / Online Store
            </a>
        </div>
      </div>

      <div className="flex-container">
        <div className="card-container">
          <div className="card">
            <div dangerouslySetInnerHTML={{ __html: pageContent.content }}></div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Appointments;