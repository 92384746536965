import React from 'react';
import { useQuery } from 'react-query';
import Slider from 'react-slick';
import { fetchCollection } from '../api';
import { Slide } from '../types';

const SimpleSlider = () => {

  const { isLoading, data } = useQuery("slides", () => fetchCollection('get/slides'));

  const slides: Slide[] = data && data.entries;

  const settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className="slider">
      <Slider {...settings}>
        {slides.map((slide: Slide) => {
          return (
            <div key={slide._id}>
              <img src={`https://www.salon304.net${slide.image.path}`} alt="" width="100%" />
            </div>
          )
        })}
      </Slider>
      <a href="https://www.vagaro.com/salon304" target="_blank" rel="noreferrer" className="button">
        <i className="fas fa-calendar-check"></i> Appointments / Online Store
        </a>
    </div>
  );
}

export default SimpleSlider;