import React from 'react';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
  render() {
    const date = new Date();
    const currentYear = date.getFullYear();
    return (
      <footer>
        <span>© {currentYear} Salon 304</span>
        <span>
          <Link to="/directions">Directions</Link> |
          <Link to="/contact">Contact Us</Link>
        </span>
      </footer>
    )
  }
}

export default Footer;