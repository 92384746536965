import React from 'react';
import { Link } from 'react-router-dom';

interface HeaderState {
  mobileMenuOpen: boolean;
}
class Header extends React.Component<any, HeaderState> {

  state = { mobileMenuOpen: false };

  componentDidMount() {
    window.addEventListener('resize', () => (
      this.state.mobileMenuOpen === true && this.setState({ mobileMenuOpen: false }))
    )
  }

  toggleMobileMenu = () => {
    this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen });
  }
  render() {
    const { mobileMenuOpen } = this.state;
    console.log('bobtest updated');

    return (
      <header>
        <div className="logo-wrapper">
          <Link to="/">
            <img src="http://salon304.net/images/logo.svg" width="200" height="auto" alt="Salon304" />
          </Link>
        </div>
        <div className="menu-wrapper">

          <div className="top-menu">
            <span><i className="fas fa-phone"></i> <span>724-942-4247</span></span>
            <span><i className="fas fa-envelope"></i> <span>salon304.hair@gmail.com</span></span>
            <ul>
              <li><Link to="/appointments">Appointments / Online Store</Link></li>
              <li><Link to="/directions">Directions</Link></li>
            </ul>
            <div className="hamburger-menu">
              <i className="fas fa-bars" onClick={() => this.toggleMobileMenu()}></i>
            </div>
          </div>

          <div className="main-menu">
            <ul>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/products">Products</Link></li>
              <li><Link to="/stylists">Stylists</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>

          {mobileMenuOpen && (
            <div className="mobile-menu" onClick={() => this.setState({ mobileMenuOpen: false })}>
              <i className="fa fa-times-circle close-modal-icon" aria-hidden="true" onClick={() => this.setState({ mobileMenuOpen: false })}></i>
              <ul className="main-mobile-links">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/services">Services</Link></li>
                <li><Link to="/products">Products</Link></li>
                <li><Link to="/stylists">Stylists</Link></li>
                <li><Link to="/contact">Contact</Link></li>
                <li><Link to="/appointments">Online Store</Link></li>
                <li><Link to="/appointments">Appointments</Link></li>
                <li><Link to="/directions">Directions</Link></li>
              </ul>
            </div>
          )}

        </div>
      </header>
    )
  }
}

export default Header;