import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ClientLayout } from './layouts';
import * as Pages from './pages';

const App = () => {
  return (
    <ClientLayout>
      <Switch>
        <Route path="/appointments" component={Pages.Appointments} />
        <Route path="/contact" component={Pages.Contact} />
        <Route path="/directions" component={Pages.Directions} />
        <Route path="/products" component={Pages.Products} />
        <Route path="/services" component={Pages.Services} />
        <Route path="/stylists" component={Pages.Stylists} />
        <Route path="/" component={Pages.Home} />
      </Switch>
    </ClientLayout>
  );
};

export default App;
